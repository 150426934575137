import { Text, Title } from "@clipboard-health/ui-react";
import { CardContent, List, Stack } from "@mui/material";
import { formatDollarsAsUsd } from "@src/appV2/lib/Money/utils/currency";

import { useReferralStats } from "../../api/useReferralStats";
import { useWorkerToWorkplaceAffiliateExperiment } from "../../hooks/useWorkerToWorkplaceAffiliateExperiment";
import { WorkplaceReferralRouterPath } from "../../paths";
import { RefereeType, ReferralConversionStage } from "../../types";
import { ReferralCardSkeleton } from "../ReferralCardSkeleton";
import { WorkplaceReferralCard } from "../WorkplaceReferralCard/WorkplaceReferralCard";
import { ReferralStatItem } from "./ReferralStatItem";

interface ReferralStatProps {
  agentId: string;
}

export function ReferralStats({ agentId }: ReferralStatProps) {
  const isWorkerToWorkplaceAffiliateProgramEnabled = useWorkerToWorkplaceAffiliateExperiment();
  const {
    data: referralStatsData,
    isLoading: isLoadingReferralStats,
    isSuccess: isReferralStatsSuccess,
  } = useReferralStats({
    referrerId: agentId,
    type: RefereeType.WORKER,
  });

  return (
    <WorkplaceReferralCard>
      <CardContent>
        <Stack spacing={2}>
          {isWorkerToWorkplaceAffiliateProgramEnabled ? (
            <>
              <Title bold component="h2" variant="h4">
                Affiliate Program Stats
              </Title>
              <Text>
                Track your referrals and earnings. See who posted their shift and who needs an extra
                nudge from you to do so.
              </Text>
            </>
          ) : (
            <>
              <Title bold component="h2" variant="h4">
                Referrals Stats
              </Title>
              <Text>
                Track your earnings and the status of each of your referrals. See who posted their
                first shift and who might need an extra nudge from you to do so.
              </Text>
            </>
          )}

          {isLoadingReferralStats && <ReferralCardSkeleton count={3} height={{ body: 80 }} />}
          {isReferralStatsSuccess && (
            <List>
              <Stack spacing={2}>
                <ReferralStatItem
                  title="Total Earnings"
                  formattedValue={formatDollarsAsUsd(referralStatsData.totalEarnings, {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  })}
                  formattedValueLinkPath={`${WorkplaceReferralRouterPath.WORKPLACE_REFERRALS_STATS_PATH}?selectedTab=${ReferralConversionStage.CONVERTED}`}
                  infoText={
                    isWorkerToWorkplaceAffiliateProgramEnabled
                      ? "This is the total sum of bonus payments that you've earned. Your bonus gets paid once a workplace signs up with your affiliate link and their first shift is verified."
                      : "This is the total sum of bonus payments that you've earned. Your bonus gets paid once a workplace signs up with your referral link and their first shift is verified."
                  }
                />
                <ReferralStatItem
                  title="Successful Referrals"
                  formattedValueLinkPath={`${WorkplaceReferralRouterPath.WORKPLACE_REFERRALS_STATS_PATH}?selectedTab=${ReferralConversionStage.CONVERTED}`}
                  formattedValue={referralStatsData.convertedReferees.toString()}
                  infoText={
                    isWorkerToWorkplaceAffiliateProgramEnabled
                      ? "This is the total number of workplaces that have signed up using your affiliate link and had their first shift verified."
                      : "This is the total number of workplaces that have signed up using your referral link and had their first shift verified."
                  }
                />
                <ReferralStatItem
                  title="Pending Referrals"
                  formattedValueLinkPath={`${WorkplaceReferralRouterPath.WORKPLACE_REFERRALS_STATS_PATH}?selectedTab=${ReferralConversionStage.UNCONVERTED}`}
                  formattedValue={referralStatsData.unconvertedReferees.toString()}
                  infoText={
                    isWorkerToWorkplaceAffiliateProgramEnabled
                      ? "This is the total number of workplaces that have signed up using your affiliate link but have not had their first shift verified."
                      : "This is the total number of workplaces that have signed up using your referral link but have not had their first shift verified."
                  }
                />
              </Stack>
            </List>
          )}
        </Stack>
      </CardContent>
    </WorkplaceReferralCard>
  );
}
