import { type UseModalState } from "@clipboard-health/ui-react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  type ShareYourProfileForm,
  shareYourProfileFormSchema,
  WorkplaceReferralAction,
  WorkplaceReferralActionSource,
  WorkplaceReferralComponent,
} from "@src/appV2/Accounts/WorkplaceReferrals/types";
import { EmailField } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { FormProvider, useForm } from "react-hook-form";

import { useShareYourProfile } from "../../api/useShareYourProfile";
import { useWorkerToWorkplaceAffiliateExperiment } from "../../hooks/useWorkerToWorkplaceAffiliateExperiment";
import { getWorkerToWorkplaceVariantName } from "../../utils/getWorkerToWorkplaceVariantName";

interface ShareYourProfileDialogProps {
  modalState: UseModalState;
  agentId: string;
  referralCode: string;
}
export function ShareYourProfileDialog(props: ShareYourProfileDialogProps) {
  const { modalState, agentId, referralCode } = props;
  const isWorkerToWorkplaceAffiliateProgramEnabled = useWorkerToWorkplaceAffiliateExperiment();
  const formMethods = useForm<ShareYourProfileForm>({
    defaultValues: {
      email: "",
    },
    resolver: zodResolver(shareYourProfileFormSchema),
  });
  const { formState, handleSubmit, reset } = formMethods;
  const { mutateAsync: shareProfile } = useShareYourProfile({ agentId, referralCode });

  return (
    <Dialog
      open={modalState.modalIsOpen}
      aria-label="Share your profile dialog"
      onClose={() => {
        logEvent(APP_V2_USER_EVENTS.WORKPLACE_REFERRAL, {
          action: WorkplaceReferralAction.SHARE_PROFILE_DIALOG_CLOSED,
          component: WorkplaceReferralComponent.SHARE_PROFILE_DIALOG,
          variant: getWorkerToWorkplaceVariantName(isWorkerToWorkplaceAffiliateProgramEnabled),
          referralCode,
          source: WorkplaceReferralActionSource.OVERLAY_CLICKED,
        });
        modalState.closeModal();
      }}
    >
      <FormProvider {...formMethods}>
        <form
          onSubmit={handleSubmit(async ({ email }) => {
            try {
              await shareProfile({ workplace: { email } });
              logEvent(APP_V2_USER_EVENTS.WORKPLACE_REFERRAL, {
                action: WorkplaceReferralAction.PROFILE_SHARED,
                component: WorkplaceReferralComponent.SHARE_PROFILE_DIALOG,
                variant: getWorkerToWorkplaceVariantName(
                  isWorkerToWorkplaceAffiliateProgramEnabled
                ),
                referralCode,
                email,
              });
              modalState.closeModal();
              reset();
              return true;
            } catch {
              return false;
            }
          })}
        >
          <DialogTitle>Share Your Profile</DialogTitle>
          <DialogContent>
            <DialogContentText paragraph>
              Enter the email of the workplace staff member you want to share your personalized
              referral link with.
            </DialogContentText>
            <EmailField name="email" />
          </DialogContent>
          <DialogActions>
            <Button
              disabled={formState.isSubmitting}
              onClick={() => {
                logEvent(APP_V2_USER_EVENTS.WORKPLACE_REFERRAL, {
                  action: WorkplaceReferralAction.SHARE_PROFILE_DIALOG_CLOSED,
                  component: WorkplaceReferralComponent.SHARE_PROFILE_DIALOG,
                  variant: getWorkerToWorkplaceVariantName(
                    isWorkerToWorkplaceAffiliateProgramEnabled
                  ),
                  referralCode,
                  source: WorkplaceReferralActionSource.BUTTON_CLICKED,
                });
                modalState.closeModal();
              }}
            >
              Close
            </Button>
            <Button type="submit" disabled={formState.isSubmitting}>
              Share
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
}
